<template>
    <div>
        <Header />
           
            <div class="container">
                <form action="" class="form-horizontal">
                    <div class="form-group left">
                        <h2><b>Cliente</b></h2>
                        <!--<small>2021</small>-->
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-2">Código Cliente: {{form.id}}</label>
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-2">Nombre</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="nombre" id="nombre" v-model="form.nombre">
                        </div>
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-3">Apellido Paterno</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="apellido_paterno" id="apellido_paterno" v-model="form.apellido_paterno">
                        </div>
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-3">Apellido Materno</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="apellido_materno" id="apellido_materno" v-model="form.apellido_materno">
                        </div>
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-2">Empresa</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="empresa" id="empresa" v-model="form.empresa">
                        </div>
                    </div>
                    <br>
                    <div class="mt-2 form-group left">
                        <h4>Dirección</h4>
                    </div>
                    <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">Calle</label>
                            <div class="col sm-8">
                                <input type="text" class="form-control" name="calle" id="calle" v-model="form.calle">
                            </div>
                    </div>
                    <div class="mt-2 form-group left row">
                        <div class="col">
                            <label for="" class="control-label col-sm-4">Num. Exterior</label>
                            <div class="col sm-2">
                                <input type="text" class="form-control" name="numero_ext" id="numero_ext" v-model="form.numero_ext">
                            </div>
                        </div>
                        <div class="col">
                            <label for="" class="control-label col-sm-4">Num. Interior</label>
                            <div class="col sm-2">
                                <input type="text" class="form-control" name="numero_int" id="numero_int" v-model="form.numero_int">
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-2">Colonia</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="colonia" id="colonia" v-model="form.colonia">
                        </div>
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-2">Localidad</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="localidad" id="localidad" v-model="form.localidad">
                        </div>
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-2">Municipio</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="municipio" id="municipio" v-model="form.municipio">
                        </div>
                    </div>
                    <div class="mt-2 form-group left row">
                        <div class="col">
                            <label for="" class="control-label col-sm-4">Código Postal</label>
                            <div class="col sm-4">
                                <input type="text" class="form-control" name="cp" id="cp" >
                            </div>
                        </div>
                        <div class="col">
                            <label for="" class="control-label col-sm-4">Estado</label>
                            <div class="col sm-10">
                                <input type="text" class="form-control" name="estado" id="estado" v-model="form.estado">
                            </div>
                        </div>
                    </div>

                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-2">País</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="pais" id="pais" v-model="form.pais">
                        </div>
                    </div>
                    <br>
                    <div class="mt-2 form-group left">
                        <h4>Contacto</h4>
                    </div>
                    <div class="mt-2 form-group left">
                        <label for="" class="control-label col-sm-2">Correo</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="correo" id="correo" v-model="form.correo">
                        </div>
                    </div>
                    <div class="mt-2 form-group left row">
                        <div class="col">
                            <label for="" class="control-label col-sm-2">Telefono</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="telefono" id="telefono" v-model="form.telefono">
                        </div>
                        </div>
                        <div class="col">
                            <label for="" class="control-label col-sm-2">Celular</label>
                            <div class="col sm-10">
                                <input type="text" class="form-control" name="celular" id="celular" v-model="form.celular">
                            </div>
                        </div>
                    </div>


                    <br>
                    <div class="mt-2 form-group">
                        <button type="button" class="btn btn-primary" v-on:click="guardar()">Guardar</button>
                        <button type="button" class="btn btn-dark margen" v-on:click="salir()">Salir</button>
                        <b-button @click="makeToast('Titulo','Texto mensaje','danger')">Show Toast</b-button>
                    </div>
                </form>
            </div>
            <br>
        <Footer />
        
       
    </div>
</template>


<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default ({
    name:'ClientesNuevo',
    data: function(){
        return {
            success_show: false,
            form: {
                "nombre" : "", 
                "apellido_paterno" : "",
                "apellido_materno":"",
                "empresa" :"",
                "correo" : "", 
                "calle" : "",
                "numero_ext" : "",
                "numero_int" : "",
                "colonia" : "",
                "localidad" : "",
                "municipio" : "",
                "estado" : "",
                "pais" : "",
                "telefono" : "",
                "celular" : "", 
                "token" : ""
            }
        }
    },
    components:{
        Header,
        Footer
    },
    methods:{
        guardar(){
            this.form.token = localStorage.getItem("token");
            //console.log(this.form);
            //axios.post("http://localhost:88/sf_api/clientes", this.form)
            axios.post("http://www.mareags.com/sf_api/clientes", this.form)
            .then(data =>{
                console.log(data); 
                this.makeToast("AVISO","Nuevo Cliente creado","success");
                alert("Se agregó el Nuevo cliente con Éxito");
                this.$router.push("/clientes");
            }).catch(e =>{
                console.log(e); 
                this.makeToast("Error","ERROR al agregar el Nuevo Cliente","danger");
                alert("ERROR: No se pudo agregar el Nuevo Cliente");
            })
            
        },
        salir(){
             //console.log("Boton Salir");
             this.$router.push("/clientes");
        },
        makeToast(titulo,texto,tipo) {
            this.toastCount++
            this.$bvToast.toast(texto, {
            title: titulo,
            variant: tipo,
            autoHideDelay: 10000,
            appendToast: true
            })
        }

    }
})
</script>

<style scoped>
.left{
   text-align: left;
 };
 .margen{
   margin-left: 15px;
   margin-right: 15px;;
 }
 .alerta{
    position: fixed;
    width: 25%;
    z-index: 100;
    top: .5rem;
    right: 1rem;
 }
</style>

