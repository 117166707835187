import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue';
import Clientes from '../views/Clientes.vue';
import ClientesEditar from '../views/ClientesEditar.vue';
import ClientesNuevo from '../views/ClientesNuevo.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: Clientes
  },
  {
    path: '/clientes_edit/:id',
    name: 'ClientesEditar',
    component: ClientesEditar
  },
  {
    path: '/clientes_new',
    name: 'ClientesNuevo',
    component: ClientesNuevo
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
