import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueAxios from 'vue-axios'
import Axios from 'axios'
import { BVToastPlugin } from "bootstrap-vue"



Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueAxios, Axios);
Vue.use(BVToastPlugin);






// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//import 'vue-toast-notification/dist/theme-sugar.css'
//import 'vue-toast-notification/dist/theme-default.css';





new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
