<template>
    <div>
        <Header/> 
        <div class="container">
            <form action="" class="form-horizontal">
                <div class="form-group left">
                    <h2><b>Cliente</b></h2>
                    <!--<small>2021</small>-->
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">Código Cliente: {{form.id}}</label>
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">Nombre</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="nombre" id="nombre" v-model="form.nombre">
                    </div>
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-3">Apellido Paterno</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="apellido_paterno" id="apellido_paterno" v-model="form.apellido_paterno">
                    </div>
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-3">Apellido Materno</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="apellido_materno" id="apellido_materno" v-model="form.apellido_materno">
                    </div>
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">Empresa</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="empresa" id="empresa" v-model="form.empresa">
                    </div>
                </div>
                <br>
                <div class="mt-2 form-group left">
                    <h4>Dirección</h4>
                </div>
                <div class="mt-2 form-group left">
                   <label for="" class="control-label col-sm-2">Calle</label>
                        <div class="col sm-8">
                            <input type="text" class="form-control" name="calle" id="calle" v-model="form.calle">
                        </div>
                </div>
                <div class="mt-2 form-group left row">
                    <div class="col">
                         <label for="" class="control-label col-sm-4">Num. Exterior</label>
                        <div class="col sm-2">
                            <input type="text" class="form-control" name="numero_ext" id="numero_ext" v-model="form.numero_ext">
                        </div>
                    </div>
                    <div class="col">
                        <label for="" class="control-label col-sm-4">Num. Interior</label>
                        <div class="col sm-2">
                            <input type="text" class="form-control" name="numero_int" id="numero_int" v-model="form.numero_int">
                        </div>
                    </div>
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">Colonia</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="colonia" id="colonia" v-model="form.colonia">
                    </div>
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">Localidad</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="localidad" id="localidad" v-model="form.localidad">
                    </div>
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">Municipio</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="municipio" id="municipio" v-model="form.municipio">
                    </div>
                </div>
                <div class="mt-2 form-group left row">
                    <div class="col">
                        <label for="" class="control-label col-sm-4">Código Postal</label>
                        <div class="col sm-4">
                            <input type="text" class="form-control" name="cp" id="cp" >
                        </div>
                    </div>
                    <div class="col">
                        <label for="" class="control-label col-sm-4">Estado</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="estado" id="estado" v-model="form.estado">
                        </div>
                    </div>
                </div>

                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">País</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="pais" id="pais" v-model="form.pais">
                    </div>
                </div>
                <br>
                <div class="mt-2 form-group left">
                    <h4>Contacto</h4>
                </div>
                <div class="mt-2 form-group left">
                    <label for="" class="control-label col-sm-2">Correo</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="correo" id="correo" v-model="form.correo">
                    </div>
                </div>
                <div class="mt-2 form-group left row">
                    <div class="col">
                        <label for="" class="control-label col-sm-2">Telefono</label>
                    <div class="col sm-10">
                        <input type="text" class="form-control" name="telefono" id="telefono" v-model="form.telefono">
                    </div>
                    </div>
                    <div class="col">
                        <label for="" class="control-label col-sm-2">Celular</label>
                        <div class="col sm-10">
                            <input type="text" class="form-control" name="celular" id="celular" v-model="form.celular">
                        </div>
                    </div>
                </div>


                <br>
                <div class="mt-2 form-group">
                    <button type="button" class="btn btn-primary" v-on:click="editar()">Editar</button>
                    <button type="button" class="btn btn-danger margen" v-on:click="eliminar()">Eliminar</button>
                    <button type="button" class="btn btn-dark margen" v-on:click="salir()">Salir</button>
                </div>
            </form>
            <br>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
    name:'ClientesEditar',
    components:{
        Header,
        Footer
    },
    data: function(){
        return {
            form: {
                "id" : "",
                "nombre" : "", 
                "apellido_paterno" : "",
                "apellido_materno":"",
                "empresa" :"",
                "correo" : "", 
                "calle" : "",
                "numero_ext" : "",
                "numero_int" : "",
                "colonia" : "",
                "localidad" : "",
                "municipio" : "",
                "estado" : "",
                "pais" : "",
                "telefono" : "",
                "celular" : "", 
                "token" : ""
            }
        }
    },
    methods:{
        editar(){
            //axios.put("http://localhost:88/sf_api/clientes",this.form)
            axios.put("http://www.mareags.com/sf_api/clientes",this.form)
            .then(data=>{
                console.log(data);
            })
            
        },
        salir(){
            this.$router.push("/clientes");
        },
        eliminar(){
            //Envío por header
            var enviar = {
                "id" : this.form.id,
                "token" : this.form.token
            };
            //console.log(enviar);
            //axios.delete("http://localhost:88/sf_api/clientes", { headers : enviar})
            axios.delete("http://www.mareags.com/sf_api/clientes", { headers : enviar})
            .then(datos => {
                console.log(datos);
                this.$router.push("/clientes");
            });
        }
    },
    mounted: function(){
        this.form.id =  this.$route.params.id;
        //console.log(this.clienteId);
        //http://www.mareags.com/sf_api
        //axios.get("http://localhost:88/sf_api/clientes?id=" + this.form.id)
        axios.get("http://www.mareags.com/sf_api/clientes?id=" + this.form.id)
        .then(datos=>{
            //console.log(datos);
            //Igual los datos del API con las variables
            this.form.nombre = datos.data[0].nombre;
            this.form.apellido_paterno = datos.data[0].apellido_paterno;
            this.form.apellido_materno = datos.data[0].apellido_materno;
            this.form.empresa = datos.data[0].empresa;
            this.form.correo = datos.data[0].correo;
            this.form.calle = datos.data[0].calle;
            this.form.numero_ext = datos.data[0].numero_ext;
            this.form.numero_int = datos.data[0].numero_int;
            this.form.colonia = datos.data[0].colonia;
            this.form.localidad = datos.data[0].localidad;
            this.form.municipio = datos.data[0].municipio;
            this.form.estado = datos.data[0].estado;
            this.form.pais = datos.data[0].pais;
            this.form.telefono = datos.data[0].telefono;
            this.form.celular = datos.data[0].celular;
            this.form.token = localStorage.getItem("token");
            //console.log(this.form);
        })
    }
}
</script>

<style scoped>
.left{
   text-align: left;
 };
 .margen{
   margin-left: 15px;
   margin-right: 15px;;
 }
</style>
