<template>
    <div>
        <Header/>
        
        <div class="container">

       
            <div class="row">
                <div class="btn-group special" role="group" style="display: flex;">
                    <router-link tag="button" class="btn btn-outline-info btn-lg btn-block" style="flex:1" to="/clientes">
                        Clientes <b-icon icon="journal-bookmark-fill" aria-hidden="true"></b-icon>
                    </router-link>
                    <router-link tag="button" class="btn btn-outline-info btn-lg btn-block" style="flex:1" to="/clientes">
                        Refacciones <b-icon icon="upc-scan" aria-hidden="true"></b-icon>
                    </router-link>
                    <router-link tag="button" class="btn btn-outline-info btn-lg btn-block" style="flex:1" to="/clientes">
                        Servicios <b-icon icon="truck" aria-hidden="true"></b-icon>
                    </router-link>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="btn-group special" role="group" style="display: flex;">
                    <router-link tag="button" class="btn btn-primary btn-lg btn-block" style="flex:1" to="/clientes">
                        Orden de Servicio <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
                    </router-link>
                    <router-link tag="button" class="btn btn-dark btn-lg btn-block" style="flex:1" to="/clientes">
                        Almacen <b-icon icon="archive" aria-hidden="true"></b-icon>
                    </router-link>
                    <router-link tag="button" class="btn btn-secondary btn-lg btn-block" style="flex:1" to="/clientes">
                        Usuarios <b-icon icon="person-circle" aria-hidden="true"></b-icon>
                    </router-link>
                </div>
            </div>


         </div>


        <Footer/>
    </div>
</template>


<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
    name:'Dashboard',
    components:{
        Header,
        Footer
    }
}
</script>

<style lang="stylus" scoped>

</style>
