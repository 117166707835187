<template>
    <div>
        <Header/>     
        
        <div class="container izquierda">
            <div align="left">  
                <h2><b>Clientes</b></h2>
            </div>
            <br>

            <button class="btn btn-primary" v-on:click="nuevo">+ Nuevo Cliente</button>
            <br>
            <br>

            <table class="table table-hover">
                <thead>
                    <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Empresa</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Direccion</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Celular</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="cliente in Listaclientes" :key="cliente.id" v-on:click="editar(cliente.id)">
                    <th scope="row">{{cliente.id}}</th>
                    <td>{{cliente.empresa}}</td>
                    <td>{{cliente.nombre}} {{cliente.apellido_paterno}} {{cliente.apellido_materno}}</td>
                    <td>{{cliente.calle}} {{cliente.numero_ext}} {{cliente.numero_int}}, {{cliente.colonia}}, {{cliente.municipio}}, {{cliente.estado}}</td>
                    <td>{{cliente.correo}}</td>
                    <td>{{cliente.celular}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        <br>
        

        <Footer/>
    </div>
</template>


<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
export default {
    name:'Clientes',
    data(){
        return {
            Listaclientes:null,
            pagina: 1
        }
    },
    components:{
        Header,
        Footer
    },
    methods:{
        editar(id){
            //console.log(id);
            this.$router.push('/clientes_edit/' + id);
        },
        nuevo(){
            this.$router.push('/clientes_new/');
        }
    },
    mounted: function(){
        //http://www.mareags.com/sf_api
        //let direccion = "http://localhost:88/sf_api/clientes?page=" + this.pagina;
        let direccion = "http://www.mareags.com/sf_api/clientes?page=" + this.pagina;
        axios.get(direccion).then( data => {
            //console.log(data);
            this.Listaclientes = data.data;
        });
    }
}
</script>

<style scoped>
    .izquierda{
        text-align: left;
    }
</style>
